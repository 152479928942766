import { FC } from 'react';
import { Space } from 'antd';
import { useSelector, useDispatch } from 'dva';
import { useIntl } from 'react-intl';
import { Button, Modal, PlatformCard, Typography } from 'iglooform';
import styles from './index.less';
import { useEffect } from 'react';

const cardBadge: Record<string, string> = {
  uap: 'UAP',
  reconciliation: 'Reconciliation',
  'anti-fraud': 'Anti-Fraud',
};

const countryCodeMap: Record<Country, string> = {
  Vietnam: 'vn',
  Indonesia: 'id',
  Thailand: 'th',
  Philippines: 'ph',
  Malaysia: 'my',
  Singapore: 'sg',
};

const SelectPlatform: FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { platforms } = useSelector<ReduxState, GlobalState>(
    (state) => state.global,
  );

  useEffect(() => {
    dispatch({
      type: 'global/setData',
      payload: {
        selectedTopMenu: null,
      },
    });
  }, []);

  const enterPlatform = (key: string) => {
    dispatch({
      type: 'global/getPermissions',
      payload: {
        currentPlatform: key,
      },
    });
  };

  const onCardClick = (platform: Platform) => {
    if (['uap', 'admin_v3'].includes(platform.kind)) {
      const countryCode = countryCodeMap[platform.countries[0]];
      const link = `https://uap-${countryCode}.${API_URL.split('.')
        .slice(1)
        .join('.')}`;

      const { destroy } = Modal.confirm({
        title: formatMessage({ id: 'Confirm' }),
        content: (
          <Typography level="body1">
            {formatMessage(
              {
                id:
                  'Dear User,\n' +
                  'You are currently logged into the previous version of our admin portal. While you can still log in, this project is no longer accessible from this platform. To access the project and all its features, please log in to our upgraded portal at {url}.\n' +
                  'Thank you for your understanding!',
              },
              {
                url: (
                  <Button
                    type="link"
                    href={link}
                    target="_blank"
                    style={{ lineHeight: 'unset' }}
                    onClick={() => destroy()}
                  >
                    <Typography level="link">{link}</Typography>
                  </Button>
                ),
              },
            )}
          </Typography>
        ),
        onOk: () => window.open(link),
      });
      return;
    }

    enterPlatform(platform?.key);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {formatMessage({
          id: 'Please Select Platform',
          defaultMessage: 'Please Select Platform',
        })}
      </div>
      <Space className={styles.platforms} size={[40, 40]} wrap>
        {platforms?.map((platform) => (
          <PlatformCard
            key={platform?.key}
            onClick={() => onCardClick(platform)}
            platform={platform}
            badgeText={
              platform.kind in cardBadge ? cardBadge[platform.kind] : void 0
            }
          />
        ))}
      </Space>
    </div>
  );
};

export default SelectPlatform;
